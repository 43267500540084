import Vue from 'vue'
import App from './App.vue'
import api from '@/api/tetis'

Vue.config.productionTip = false

import './plugins/axios'
import './plugins/buefy'

import store from './store'



const account = await api.get('Account');
if(account.data?.value){
  store.commit('basic', { key: 'Account', value: account.data.value[0]})
}

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
