<template>
    <div class="columns is-vcentered">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>    
        <div class="column is-2">
        </div>
        <div class="column is-8">
            <b-tabs type="is-boxed">
                <b-tab-item label="Cadastro de usuários" icon="account-multiple-plus">
                    <div class="card">
                        <div class="content p-6">
                            <b-field label="Nome Completo" :type="(errors && errors['Name']) ? 'is-danger' : ''" :message="(errors && errors['Name']) ? errors['Name'] : ''">
                                <b-input icon="alphabetical-variant" v-model="field.Name"></b-input>
                            </b-field>

                            <b-field label="Email"  :type="(errors && errors['Email']) ? 'is-danger' : ''" :message="(errors && errors['Email']) ? errors['Email'] : ''">
                                <b-input icon="email" type="email" v-model="field.Email" autocomplete="new-password"></b-input>
                            </b-field>                    

                            <b-field label="Senha" :type="(errors && errors['Password']) ? 'is-danger' : ''" :message="(errors && errors['Password']) ? errors['Password'] : ''">
                                <b-input icon="lock" v-model="field.Password" type="password" autocomplete="new-password"></b-input>
                            </b-field>               

                            <b-field label="Equipe" :type="(errors && errors['TeamId']) ? 'is-danger' : ''" :message="(errors && errors['TeamId']) ? errors['TeamId'] : ''">
                                <b-select icon="account-group" placeholder="Selecione sua Equipe" v-model="field.TeamId" expanded>
                                    <option v-for="team in teams" :key="team.Id" :value="team.Id">{{ team.Name }}</option>
                                </b-select>
                            </b-field>                    
                        </div> 
                            <footer class="card-footer">
                                <b-button icon-left="trash-can-outline" type="is-danger" @click="clear" class="card-footer-item m-3">Limpar</b-button>
                                <b-button icon-left="content-save" type="is-primary" @click="submit" class="card-footer-item m-3">Salvar</b-button>
                            </footer>                               
                    </div>       
                </b-tab-item>
                <b-tab-item label="Alterar Senha" icon="lock-check-outline">
                    <div class="card">
                        <div class="content p-6">
                                <b-field label="Email"  :type="(errors && errors['NewEmail']) ? 'is-danger' : ''" :message="(errors && errors['NewEmail']) ? errors['NewEmail'] : ''">
                                <b-input icon="email" type="email" v-model="field.NewEmail" autocomplete="new-password"></b-input>
                            </b-field>                    

                            <b-field label="Nova senha" :type="(errors && errors['NewPassword']) ? 'is-danger' : ''" :message="(errors && errors['NewPassword']) ? errors['NewPassword'] : ''">
                                <b-input icon="lock" v-model="field.NewPassword" type="password" autocomplete="new-password"></b-input>
                            </b-field>               
                  
                        </div> 
                            <footer class="card-footer">
                                <b-button icon-left="trash-can-outline" type="is-danger" @click="clear" class="card-footer-item m-3">Limpar</b-button>
                                <b-button icon-left="content-save" type="is-primary" @click="submitNew" class="card-footer-item m-3">Salvar</b-button>
                            </footer>                               
                    </div>       


                </b-tab-item>
            </b-tabs>
        </div>
        <div class="column is-2">
        </div>
    </div>
</template>

<script>
import api from '@/api/tetis'


export default {
    props: {    
 
    },    
    computed: {
    },
    created(){

        this.loadTeams();

    },      
    data() {
        return {
            loading : false,
            field : {
                Name : '',
                Email : '',
                Password : '',
                TeamId : null,
                NewPassword : '',
                NewEmail : ''
            },
            errors : {
                Name : '',
                Email : '',
                Password : '',
                TeamId : '',
                NewPassword : '',
                NewEmail : ''
            },
            teams : []
        }
    },
    methods: {
        async loadTeams(){
            this.loading = true;
            const teams = await api.get('Teams');

            this.teams = teams.data.value

            this.loading = false;

        },
        clear(){
            this.field = {
                Name : '',
                Email : '',
                Password : '',
                TeamId : null,
                NewPassword : '',
                NewEmail : ''
            };
            this.errors = {
                Name : '',
                Email : '',
                Password : '',
                TeamId : '',
                NewPassword : '',
                NewEmail : ''
            };
        },
        clearErrors(){
            this.errors = {
                Name : '',
                Email : '',
                Password : '',
                TeamId : '',
                NewPassword : '',
                NewEmail : ''
            };
        },
        async submit(){
            const vl = this.validate();
            if(vl === false) return;

            this.loading = true;
            let id = null;
            //verficar se o email existe
            const {data} = await api.get(`Users?$select=Id&$filter=Email+eq+'${this.field.Email}'`);


            if(data?.value && data.value.length > 0){
                id = data.value[0].Id;
            }
                

            const data_body = {
                Name : this.field.Name,
                Email : this.field.Email,
                Password : this.field.Password,
                ProfileId :  process.env.VUE_APP_PLOOMES_PROFILE_ID,
                Integration : false,
                Suspended : false,
                Teams : [
                    {
                        TeamId : this.field.TeamId
                    }
                ]
            }

           try{
               if(id === null){
                   await api.post('Users', data_body);
               }else{
                    await api.patch(`Users(${id})`, data_body);
               }
                this.loading = false;
                this.clear();
                this.$buefy.notification.open({
                    duration: 5000,
                    message: (id === null) ? `Usurário criado com sucesso!` : `Usurário alterado com sucesso!`,
                    position: 'is-bottom-right',
                    type: 'is-success',
                    hasIcon: true
                });

           }catch(error){
               this.loading = false;
               if (error.response && error.response.status === 401) {
                    this.errors.Email = 'Esse e-mail já está em uso em outra conta Ploomes, tente usar outro e-mail';
                    this.$buefy.notification.open({
                            duration: 5000,
                            message: `Erro ao cadastrar o usuário, Esse e-mail já está em uso em outra conta Ploomes, tente usar outro e-mail`,
                            position: 'is-bottom-right',
                            type: 'is-danger',
                            hasIcon: true
                    });                    
               }else{
                    this.loading = false; 
                    this.$buefy.notification.open({
                            duration: 5000,
                            message: `Erro ao cadastrar o usuário, tente novamente mais tarde, ${error.message}`,
                            position: 'is-bottom-right',
                            type: 'is-danger',
                            hasIcon: true
                    });
               } 
           } 




        },
        async submitNew(){
            const vl = this.validateNew();
            if(vl === false) return;

            this.loading = true;


            const {data} = await api.get(`Users?$select=Id&$filter=Email+eq+'${this.field.NewEmail}'`);


            if(data?.value && data.value.length > 0){
                
                    const data_post = {
                        Password : this.field.NewPassword,
                        Suspended : false,                        
                    }

                try{
                    await api.patch(`Users(${data.value[0].Id})`, data_post);
                    this.loading = false;
                    this.clear();
                    this.$buefy.notification.open({
                        duration: 5000,
                        message: `Usurário alterado com sucesso!`,
                        position: 'is-bottom-right',
                        type: 'is-success',
                        hasIcon: true
                    });

                }catch(error){
                    this.loading = false; 
                    this.$buefy.notification.open({
                            duration: 5000,
                            message: `Erro ao alterar o usuário, tente novamente mais tarde, ${error.message}`,
                            position: 'is-bottom-right',
                            type: 'is-danger',
                            hasIcon: true
                    });
                } 

            }else{
                this.loading = false;
                this.errors.NewEmail = 'Esse e-mail não esta cadastrado';
                this.$buefy.notification.open({
                        duration: 5000,
                        message: `Esse e-mail não esta cadastrado`,
                        position: 'is-bottom-right',
                        type: 'is-danger',
                        hasIcon: true
                });                 
            }




        },
        validate(){
            this.clearErrors();    
            let vl = true;
            if(this.field.Name.trim() == ''){
                this.errors.Name = 'O campo Nome completo é obrigatório';
                vl = false;
            }

            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;   
            if(!regex.test(this.field.Email)){
                this.errors.Email = 'Email informado é inválido';
                vl = false;
            }    
            
            if(this.field.Password.trim() == ''){
                this.errors.Password = 'O campo Senha é obrigatório';
                vl = false;
            }            

            if(this.field.Password.length < 8){
                this.errors.Password = 'É preciso ao menos oito caracteres';
                vl = false;
            }              
            
            if(!this.field.TeamId){
                this.errors.TeamId = 'O campo Equipe é obrigatório';
                vl = false;
            }                  

            return vl;
        },
        validateNew(){
            this.clearErrors();    
            let vl = true;

            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;   
            if(!regex.test(this.field.NewEmail)){
                this.errors.NewEmail = 'Email informado é inválido';
                vl = false;
            }    
            
            if(this.field.NewPassword.trim() == ''){
                this.errors.NewPassword = 'O campo Senha é obrigatório';
                vl = false;
            }            

            if(this.field.NewPassword.length < 8){
                this.errors.NewPassword = 'É preciso ao menos oito caracteres';
                vl = false;
            }            
              

            return vl;
        }        
    }

}
</script>

<style>

</style>