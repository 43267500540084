import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'app-ploomes-unimed',
  storage: window.localStorage,
  reducer: (persistedState) => {
    const stateFilter = Object.assign({}, persistedState)
    const blackList = ['Account']

    blackList.forEach((item) => {
      delete stateFilter[item]
    })

    return stateFilter
  }
})

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    /* User */

    Account : {
      LogoUrl : '',
      Name : ''
    }

  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

  },
  actions: {

  }, 
  plugins: [vuexPersist.plugin]
})