
import axios from 'axios'

const apiTetis = axios.create({
    baseURL: process.env.VUE_APP_TETIS_API_URL, 
    headers: {
      
      'Content-Type': 'application/json',
      'User-Key' : process.env.VUE_APP_TETIS_API_KEY,
    },
  });

  


apiTetis.interceptors.request.use(requestInterceptor)
apiTetis.interceptors.response.use(responseInterceptor, responseErrorInterceptor)


function requestInterceptor(request) {
    
/*     const UserId = store.state?.user?.Id;
    if (UserId) {
        request.headers['User-Id'] = `${UserId}`;
      }        */
    request.headers['X-Axios-Request'] = true
    return request
}

async function responseInterceptor(response) {
    // Perdeu autenticação
/*     if (response.headers['login-page'] && !location.pathname.includes('Login')) {
        const res = await handleRelogin(response)
        return res
    }
 */
    
/*     Vue.prototype.$buefy.toast.open({
        duration: 10000,
        message: 'Algum Erro aconteceu, procure o administrador do sistema :( <br/>',
        position: 'is-top-right',
        queue: false,
        type: 'is-danger'
    });     */
    return response
}

async function responseErrorInterceptor(error) {
    
    if (error.response.status === 500) {
        throw error
    }else{
        throw error
    }

}

export default apiTetis;