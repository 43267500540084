<template>
 <div>
    <b-navbar>
        <template #brand>
            <b-navbar-item>
                <img
                    :src="url"
                    :alt="url_description"
                >
            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item href="#">
                {{url_description}}
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-primary" target="_blank" href="https://app.ploomes.com">
                        <strong>Ir para Ploomes</strong>
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
    <users-cadastro></users-cadastro>
 </div>
</template>
<script>
import './scss/style-default.scss'

import UsersCadastro from './components/UsersCadastro';


export default {

    components: {
        UsersCadastro
    },

  
    data() {
        return {
            url: this.$store.state.Account.LogoUrl,
            url_description: this.$store.state.Account.Name,
        };
    },
    created() {

    },
    methods: {

    },
};

</script>
